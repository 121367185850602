module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-starberry-lomondgroup/gatsby-browser.js'),
      options: {"plugins":[],"news_insight":"about/insights","branch_finder":"branch-finder","area_cover":"about/areas-we-cover","teams":"contact/meet-the-team","guides_resources":"guides-and-resources"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0f19f4164bc92d2625f5770955ba6407"},
    }]
